import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: '/companys',
		name: 'companys',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/CompanysView.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/NewsView.vue')
	},
	{
		path: '/newsinfo',
		name: 'newsinfo',
		component: () => import('../views/NewsdetailView.vue')
	},
	{
		path: '/form',
		name: 'form',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/FormView.vue')
	},
	{
		path: '/part',
		name: 'part',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import('../views/PartView.vue')
	},
	{
		path: '/companyinfo',
		name: 'companyinfo',
		component: () => import('../views/CompanyInfoView.vue')
	},
	{
		path: '/huzhou',
		name: 'huzhou',
		component: () => import('../views/HuzhouView.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router