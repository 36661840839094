import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false

// 屏幕自适应穿透px为rem模板
const baseSize = 14; // 设计稿根字体大小
function setRem() {
  if (
    document.documentElement.clientWidth >= 762 &&
    document.documentElement.clientWidth <= 2040
  ) {
    const scale = document.documentElement.clientWidth / 1920; // 计算缩放比例
    document.documentElement.style.fontSize =
      baseSize * Math.min(scale, 2) + "px"; // 设置根字体大小
  }
  if (
    document.documentElement.clientWidth >= 300 &&
    document.documentElement.clientWidth <= 762
  ) {
    // const scale = (750) / 1920 // 计算缩放比例
    document.documentElement.style.fontSize = 62.5 + "%"; // 设置根字体大小
    // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
  }
}

setRem();
window.addEventListener("resize", setRem); // 窗口大小改变时重新计算


Vue.use(ElementUI);
new Vue({
	router,
	render: h => h(App)
}).$mount('#app')