<template>
	<div v-if="!isMobile">
		<div class="header">
			<div class="head-content flex justify-between">
				<div class="cv1" style="">
					<!-- LOGO -->
				</div>
				<div class="menu-content flex justify-end cv2">
					<div class="menu" @click="changeMenu(item)" :class="item.id==index?'actives':''"
						v-for="item in menuList" :key="item.id"> {{item.name}} </div>
				</div>
			</div>

			<div class="tt35">
				<div class="tt36">浙江省湖州市製造企業展</div>
				<div class="tt36">ビジネスマッチング</div>
				<div class="tt37">未来のための 、新たな出会いを</div>
				<div class="tt38">encounter that creates the future</div>
			</div>
			<div class="head-article flex justify-between hander" @click="goUrl('/companys')">
				<div class="" style="padding-left: 20px;background: #edf8fe;flex:1;color:#000;">
					小さな街に秘められた大きなパーワー！出展企業チェック
				</div>
				<div class="text-center" style="width:70px;background: #d2ecfb;color:#000;">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>
		<div class="menuinfo">
			<div class="menuinfo_five hander" @click="goUrl(item.url)" v-for="item in menu" :key="item">
				<div class="">
					<img :src="item.icon" class="menuinfo-icon" />
				</div>


				<div class="menuinfotitle" style="">
					{{item.name}}
				</div>
				<div class="menuinfodesc" style="">
					<p style="height: 10px;line-height: 10px;">{{item.content1}}</p>
					<p style="height: 10px;line-height: 10px;">{{item.content2}}</p>
				</div>
			</div>
		</div>

		<div class="exhibition flex flex-start">
			<div class="text-center child-to-center" style="flex:1;">
				<div class="text-blue" style="font-size: 25px;margin: 0 auto;color:#062b7c;">
					<p style="height: 10px;line-height: 10px;">Exhibition</p>
					<p style="height: 10px;line-height: 10px;">introduction</p>
				</div>
				<div class="tt34">
					展示会の紹介
				</div>
				<div class="" style="width: 70%;text-align: center;margin: 0 auto;line-height:35px;">
					<p style="height: 16px;line-height: 16px;">お互いの強みを生かしたビジネスマッチングを目指し、皆さんが</p>
					<p style="height: 16px;line-height: 16px;">より良い事業パートナーを見つけられるように</p>
				</div>
				<div class="" style="margin-top: 50px;">
					<div class="text-center blues hander" @click="goUrl('/part')"
						style="width:350px;height:60px;line-height: 60px;border-radius: 3px;margin:0 auto;">
						詳細
					</div>
				</div>
			</div>
			<div class="" style="width:50%;">
				<img src="https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/zhanhui.png"
					style="width:100%;" />
			</div>
		</div>
		<div class="company flex">
			<div class="child-to-center" style="width: 100%;">
				<div class="flex justify-start " style="width: 100%;font-weight: 700;">
					<div class="" style="width: 30%;">
						<div v-for="item in company1" :key="item" class="company-title flex justify-between">
							<div class="hander" @click="goUrl('/companyinfo?id='+item.id)">· {{item.name}}</div>
							<div v-if="item.type=='1'" style="color:#75a1be;">上場企業</div>
						</div>
					</div>
					<div class="" style="width: 30%;">
						<div v-for="item in company2" :key="item" class="company-title flex justify-between">
							<div class="hander" @click="goUrl('/companyinfo?id='+item.id)">· {{item.name}}</div>
							<div v-if="item.type=='1'" style="color:#75a1be;">上場企業</div>
						</div>
					</div>
					<div style="width:14%;"></div>
					<div class="text-center">
						<div>

							<div class="text-blue" style="font-size: 25px;margin: 0 auto;color:#062b7c;">
								<p style="height: 10px;line-height: 10px;">Exhibiting</p>
								<p style="height: 10px;line-height: 10px;">enterprises</p>
							</div>
							<div class="tt34" style="">
								出展企業
							</div>
							<div class="" style="margin-top: 50px;">
								<div class="text-center lightblue hander" @click="goUrl('/companys')"
									style="width:350px;height:60px;line-height: 60px;border-radius: 3px;margin:0 auto;">
									詳細
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
		<div class="huzhou flex flex-start" style="margin: 50px 0;">
			<div class="text-center child-to-center" style="flex:1;">
				<div class="text-blue tt33" style="">
					<p style="height: 10px;line-height: 10px;">Introduction</p>
					<p style="height: 10px;line-height: 10px;">to Huzhou</p>
				</div>
				<div class="tt34" style="">
					湖州市の紹介
				</div>
				<div class="hz11">
					<p style="height: 16px;line-height: 16px;">上海、蘇州、無錫に囲まれた太湖の南に位置し、美しい</p>
					<p style="height: 16px;line-height: 16px;">歴史風景と現代産業が融合した街になります</p>
				</div>

				<div class="" style="margin-top: 50px;">
					<div class="text-center blues hander" @click="goUrl('/huzhou')"
						style="width:350px;height:60px;line-height: 60px;border-radius: 3px;margin:0 auto;">
						詳細
					</div>
				</div>
			</div>
			<div class="hz22" style="">
				<img src="https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/huzhou.jpg"
					style="width: 100%;" />
			</div>
		</div>
		<div class="news">
			<div class="flex justify-end">
				<div class="text-center" style="width: 300px;">
					<div class="text-blue tt33" style="">
						Corporate news
					</div>
					<div class="margin-top-xs" style="font-size: 34px;">
						企業ニュース
					</div>
				</div>
			</div>

			<div class="margin-top">

				<div class="flex justify-start hander cc1" @click="goUrl('/newsinfo?id='+a.id)" style=""
					v-for="a in news" :key="a">
					<div class="w200s">
						{{a.date}}
					</div>
					<div class="cc2" style="">
						<div class="text-center"
							style="background: #76a0bd;color:#fff;height: 40px;line-height: 40px;border-radius: 5px;">
							企業ニュース</div>
					</div>
					<div class="cc44" style="">
						{{a.title}}
					</div>
					<div style="flex:1;">

					</div>
					<div class="">
						<i class="fa fa-chevron-circle-right" style="position: relative;top:2px;"></i> more
					</div>
				</div>

				<div class="flex justify-end" style="margin-top: 55px;">
					<div class=" text-center lightblue hander" @click="goUrl('/news')"
						style="width:350px;height:60px;line-height: 60px;border-radius: 3px;">
						詳細
					</div>
				</div>


			</div>

		</div>

		<FootInfo />

	</div>

</template>

<script>
	import FootInfo from '@/components/FootInfo.vue'
	import axios from 'axios';
	export default {
		data() {
			return {
				index: 1,
				menuList: [{
						id: 1,
						name: 'TOP',
						url: '/'
					},
					{
						id: 2,
						name: '展示会の紹介',
						url: '/part'
					},
					{
						id: 3,
						name: '出展企業',
						url: '/companys'
					},
					{
						id: 4,
						name: '湖州の紹介',
						url: '/huzhou'
					},
					{
						id: 5,
						name: '企業ニュース',
						url: '/news'
					},
					{
						id: 6,
						name: '事前登録',
						url: '/form'
					},
				],
				menu: [{
						name: '展示会の紹介',
						icon: require('@/assets/images/index/icon1.png'),
						url: '/part',
						content1: 'Introduction to the',
						content2: 'exhibition',
					},
					{
						name: '出展企業',
						icon: require('@/assets/images/index/icon2.png'),
						url: '/companys',
						content1: 'Exhibiting',
						content2: 'enterrises'
					},
					{
						name: '湖州の紹介',
						icon: require('@/assets/images/index/icon3.png'),
						url: '/huzhou',
						content1: 'Introduction to the',
						content2: 'Huzhou',
					},
					{
						name: '企業ニュース',
						icon: require('@/assets/images/index/icon4.png'),
						url: '/news',
						content1: 'Corporate',
						content2: 'news',
					},
					{
						name: '事前登録',
						icon: require('@/assets/images/index/icon5.png'),
						url: '/form',
						content1: 'Application',
						content2: 'for visit',
					},
				],
				company1: [],
				company2: [],
				news: [],
				newsInfo: {},
				isMobile:true
			};
		},
		components: {
			FootInfo
		},
		created() {
			var isMobile = this.detectDeviceType();
			console.log("isMobile=" + isMobile);
			if(isMobile){
				window.location.href="http://www.hzzzyzh.com/mobile/"
				return false;
			}else{
				this.isMobile = false;
			}
			this.getNewsInfo();
			this.getHomeCompany();
		},
		methods: {

			detectDeviceType() {
				const userAgent = navigator.userAgent || navigator.vendor || window.opera;
				if (/windows phone/i.test(userAgent)) {
					return true;
				}

				if (/android/i.test(userAgent)) {
					return true;
				}

				// iOS 设备  
				if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
					return true;
				}

				// 常见的桌面浏览器  
				if (/Mac OS X/i.test(userAgent) && !/like Mac OS X/i.test(userAgent)) {
					return false;
				}

				if (/Windows NT/i.test(userAgent)) {
					return false;
				}

				// 其他情况默认认为是移动设备  
				if (!this.isMobile && /Linux/i.test(userAgent)) {
					return true;
				}

				return false;

				// 如果需要更精确的检测，可以考虑使用第三方库，如 mobile-detect.js  
			},


			getNewsInfo() {
				axios.get('https://api.hzzzyzh.com/api/index/getNewsThiree')
					.then(response => {
						this.news = response.data.data;
						this.newsInfo = response.data.data[0];
					})
					.catch(error => {
						console.error(error);
					});
			},
			getHomeCompany() {
				axios.get('https://api.hzzzyzh.com/api/index/getHomeCompany')
					.then(response => {
						this.company1 = response.data.data.company1;
						this.company2 = response.data.data.company2;
					})
					.catch(error => {
						console.error(error);
					});
			},
			changeMenu(item) {
				console.log(item);
				console.log(this.index);
				if (item.id != this.index) {
					console.log("跳转");
					this.$router.push(item.url);
				}
			},
			goUrl(url) {
				this.$router.push(url);
			}
		}
	}
</script>
<style>
	#app {
		background: #fff;
	}



	.header {
		background: #333;
		width: 100%;
		height: 940px;
		color: #fff;
		position: relative;
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/indexbanner.jpg);
		font-size: 18px;
	}

	.actives {
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/bgicon.png);
	}

	.head-content {
		margin: 0 auto;
		height: 75px;
		line-height: 75px;
	}

	.menu {
		width: 13%;
		height: 75px;
		line-height: 75px;
		text-align: center;
		cursor: pointer;
	}

	.menu:hover {
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/bgicon.png);
	}

	.head-article {
		background: #000;
		position: absolute;
		right: 0;
		bottom: 0;
		width: 800px;
		height: 70px;
		line-height: 70px;
	}


	.menuinfo {
		height: 520px;
		display: flex;
		padding: 0 175px;
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/back1.png);
	}

	.menuinfo_five {
		flex: 5;
		text-align: center;
		padding-top: 125px;
	}

	.menuinfo-icon {
		width: 100px;
		height: 100px;
	}

	.exhibition {
		background: #fff;
		padding: 50px;
	}

	.company {
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/back2.png);
		height: 532px;
	}

	.company-title {
		margin-left: 180px;
		width: 80%;
		overflow-x: hidden;
		height: 72px;
		line-height: 72px;
		border-bottom: 1px #76a0bd dashed;
		color: #000;
		font-size: 17px;
	}

	.huzhou {
		background-image: url(https://zhizaoye.oss-ap-northeast-1.aliyuncs.com/assets/images/index/back3.jpg);
	}

	.news {
		background: #fff;
		padding: 60px 10%;

	}

	.blues {
		background: #062b7c;
		color: #fff;
	}

	.lightblue {
		background: #75a1be;
		color: #fff;
	}

	.cc1 {
		height: 100px;
		line-height: 100px;
		border-bottom: 1px #ccc dashed;
		font-size: 18px;
		color: #4d4d4d;
	}

	.w200s {
		width: 200px;
	}

	.cc2 {
		width: 200px;
		padding-top: 30px;
	}

	.cc44 {
		padding-left: 45px;
		width: 800px;
		overflow: hidden;
	}

	.cv1 {
		margin-left: 80px;
		width: 30%;
	}

	.cv2 {
		width: 70%;
	}

	.hz11 {
		width: 70%;
		text-align: center;
		margin: 0 auto;
		line-height: 35px;
	}

	.hz22 {
		width: 55%;
	}

	.tt33 {
		font-size: 25px;
		color: #062b7c;
	}

	.tt34 {
		font-size: 34px;
		margin: 30px 0 40px 0;
	}

	.tt35 {
		position: absolute;
		left: 200px;
		top: 400px;
		font-size: 50px;
	}

	.tt36 {
		font-size: 54px;
		letter-spacing: 6px;
	}

	.tt37 {
		font-size: 30px;
		letter-spacing: 6px;
		margin-top: 6px;
	}

	.tt38 {
		font-size: 20px;
		margin-top: 12px;
	}

	.tt39 {}

	.tt3 {}

	.menuinfotitle {
		font-size: 24px;
		color: #243f6a;
		margin: 20px 0;
	}

	.menuinfodesc {
		color: #000;
		font-size: 16px;
	}
</style>